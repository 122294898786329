import React, { useEffect, useState } from "react";

const NetworkStatus = () => {
  const [status, setStatus] = useState(0);
  const [ping, setPing] = useState(0);

  useEffect(() => {
    // Function to determine network status
    const updateNetworkStatus = () => {
      if (navigator.connection) {
        const { effectiveType, downlink, rtt } = navigator.connection;
        setPing(rtt);

        if (downlink > 2) {
          setStatus(4);
        } else if (downlink > 1) {
          setStatus(3);
        } else {
          setStatus(2);
        }
      } else {
        setStatus("Unable to determine network status");
      }
    };

    // Initial status check
    updateNetworkStatus();

    // Listen for network changes
    if (navigator.connection) {
      navigator.connection.addEventListener("change", updateNetworkStatus);
    }

    // Cleanup event listener on component unmount
    return () => {
      if (navigator.connection) {
        navigator.connection.removeEventListener("change", updateNetworkStatus);
      }
    };
  }, []);
  return (
    <div className="flex flex-row gap-1 justify-end items-end top-20 text-gray-500 right-10 bg-white px-2 py-1 rounded-xl">
      <p className="text-xs pr-4 font-semibold font-poppins">Network</p>
      {ping == 0 ? (
        <p>No Connection</p>
      ) : (
        <div className="w-1 h-2 bg-red-600 "></div>
      )}
      {status > 2 && <div className="w-1 h-3 bg-yellow-600"></div>}
      {status > 3 && <div className="w-1 h-4 bg-green-600"></div>}
    </div>
  );
};

export default NetworkStatus;
