import React, { useEffect, useState } from "react";
import { IconButton, Box, Typography } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { MyToolTip } from "../../Components";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Spinner } from "@material-tailwind/react";
import { FaEye } from "react-icons/fa";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import {
  useGetSaleVoucherByShopQuery,
  useGetSaleVoucherByDateMutation,
  useGetSaleVoucherDetailsMutation,
} from "../../ApiService/SaleVoucherApiSlice";
import { VoucherDetailsDialog } from "../../Components";
import { useNavigate } from "react-router-dom";
import { useGetCustomerQuery } from "../../ApiService/CustomerApiSlice";

const SaleReportsPage = () => {
  var today = new Date();
  const date =
    today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();

  const navigate = useNavigate();

  useEffect(() => {
    const auth = localStorage.getItem("auth");
    if (!auth) {
      navigate("/login");
    }
  }, []);

  const [startDate, setStartDate] = useState(dayjs(date));
  const [endDate, setEndDate] = useState(dayjs(date));
  const [isSearch, setIsSearch] = useState(false);
  const [detial, setDetail] = useState([]);
  const [open, setOpen] = useState(false);
  const [printInfo, setPrintInfo] = useState({});
  const { data: customer, isSuccess: customerSuccess } = useGetCustomerQuery(
    localStorage.getItem("shop_id")
  );
  const {
    data: vouchers,
    isLoading: voucherLoading,
    isSucces: voucherSuccess,
  } = useGetSaleVoucherByShopQuery(localStorage.getItem("shop_id"));

  //   const { data: SearchVoucher, isLoading: searchVoucerLoading, isSucces: SearchvoucherSuccess, refetch}= useGetSaleVucherByDateQuery();

  const [saleVoucherList, setSaleVoucherList] = useState([]);
  const [searchSaleVoucherList, setSearchSaleVoucherList] = useState([]);
  const [SearchVoucherByDate, result] = useGetSaleVoucherByDateMutation();
  const [VoucherDetails, voucherDetailsResult] =
    useGetSaleVoucherDetailsMutation();

  const columns = !voucherSuccess
    ? [
        { field: "No", headerName: "No.", width: 50 },
        {
          field: "id",
          headerName: "Voucher No",
          flex: 1,
        },
        {
          field: "customerName",
          headerName: "Customer Name",
          flex: 1,
          renderCell: (params) => {
            const customerName = customer?.data.filter(
              (cus) => cus.id === params.row.customer_id
            );
            return <div>{customerName[0]?.name}</div>;
          },
        },
        {
          field: "discount",
          headerName: "Discount",
          flex: 1,
        },
        {
          field: "total",
          headerName: "Total Amount",
          flex: 1,
        },
        {
          field: "bill",
          headerName: "Bill Amount",
          sortable: true,
          flex: 1,
        },
        {
          field: "updated_at",
          headerName: "Sale Date",
          sortable: true,
          flex: 1,
          renderCell: (params) => <p>{params.value.split("T")[0]}</p>,
        },
        {
          field: "action",
          headerName: "Action",
          sortable: true,
          flex: 1,
          renderCell: (params) => (
            <MyToolTip style={`bg-green-500`} content={"View"}>
              <IconButton onClick={() => showDailySaleDetails(params.row)}>
                <FaEye className="h-4 w-4 text-green-600 font-extrabold" />
              </IconButton>
            </MyToolTip>
          ),
        },
      ]
    : [];

  const voucherSearchByDate = async () => {
    const shopid = localStorage.getItem("shop_id");
    const start = startDate.format("YYYY-MM-DD");
    const end = endDate.format("YYYY-MM-DD");

    console.log(shopid, start, end);

    await SearchVoucherByDate({ shopid, start, end });
    setIsSearch(true);
  };

  const showDailySaleDetails = async (row) => {
    const shopid = localStorage.getItem("shop_id");
    console.log(row);
    const id = row.id;
    const customerName = customer?.data.filter(
      (cus) => cus.id === row.customer_id
    );
    setPrintInfo({ ...row, customer: customerName });
    await VoucherDetails({ shopid, id });
  };

  useEffect(() => {
    if (result.isSuccess) {
      console.log(result?.data);
      setSearchSaleVoucherList([
        ...result?.data.data.map((sale, i) => ({ ...sale, No: i + 1 })),
      ]);
    }
  }, [result]);

  useEffect(() => {
    if (voucherDetailsResult.isSuccess) {
      setDetail(voucherDetailsResult?.data.data);
      setOpen(true);
    }
  }, [voucherDetailsResult]);

  useEffect(() => {
    if (voucherLoading) {
      return;
    }
    if (!voucherLoading) {
      setSaleVoucherList([
        ...vouchers?.data.map((sale, i) => ({ ...sale, No: i + 1 })),
      ]);
    }
  }, [vouchers, voucherLoading]);

  return (
    <div className="flex flex-row w-full h-full">
      <div className="flex flex-col w-full h-full">
        <div className="flex flex-row w-full h-fit justify-between items-center">
          <p className="px-4 py-2 font-poppins text-base bg-gray-700 rounded-lg text-white w-fit m-2">
            Voucher Reports
          </p>
          <div className="flex flex-row w-fit p-2 gap-4 items-center">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                format="YYYY-MM-DD"
                defaultValue={startDate}
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                label="Choose Start Date"
                className="pr-2 w-full"
                slotProps={{ textField: { size: "small" } }}
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                format="YYYY-MM-DD"
                defaultValue={endDate}
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                label="Choose End Date"
                className="pr-2 w-full"
                slotProps={{ textField: { size: "small" } }}
              />
            </LocalizationProvider>
            <IconButton
              aria-label="delete"
              size="large"
              onClick={voucherSearchByDate}
            >
              <SearchOutlinedIcon />
            </IconButton>
          </div>
        </div>

        {/* Search Result */}
        <div className="flex flex-col w-full h-full">
          {isSearch && (
            <div className="flex flex-row items-center font-poppins">
              <IconButton
                aria-label="delete"
                size="large"
                onClick={() => setIsSearch(false)}
              >
                <ArrowBackIcon />
              </IconButton>
              <Typography>
                Search Results For Between {startDate.format("YYYY-MM-DD")} and{" "}
                {endDate.format("YYYY-MM-DD")}
              </Typography>
            </div>
          )}
          <Box sx={{ height: 600, width: "100%" }}>
            {!voucherLoading ? (
              <DataGrid
                rows={!isSearch ? saleVoucherList : searchSaleVoucherList}
                loading={voucherLoading}
                getRowClassName={(params) => {
                  return params.row.rem_qty < params.row.alert_qty
                    ? "text-red-400"
                    : "";
                }}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                onRowDoubleClick={(params) => showDailySaleDetails(params.row)}
                pageSizeOptions={[5, 10, 25, 50, 100]}
                density="compact"
                disableRowSelectionOnClick
                slots={{ toolbar: GridToolbar }}
                showCellVerticalBorder
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                  },
                }}
                //loading
              />
            ) : (
              <div className="flex flex-row w-full h-full items-center justify-center">
                <Spinner />
              </div>
            )}
          </Box>
        </div>
      </div>
      <VoucherDetailsDialog
        open={open}
        handleClose={() => setOpen(false)}
        showDetails={detial}
        print={printInfo}
      />
    </div>
  );
};

export default SaleReportsPage;
